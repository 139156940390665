import CardProductV2 from "@Components/CardProductV2";
import { Skeleton } from "@mui/material";
import Link from "@Components/Link";
import Slider from "react-slick";
import useCountdownTime from "hook/useCountdownTime";
import React, { memo, useMemo, useState } from "react";
import { Paginate } from "models/model";
import Product, { IProduct } from "models/product.model";
import { FlashSale } from "models/flashSale.model";
import Bookmark from "@Components/modal/Bookmark";

function FlashSaleSection({
   isMobile,
   paginateFlashSale,
   flashSale,
   onEndCountdownFlashSales
}: {
   isMobile: boolean;
   paginateFlashSale: Paginate<Product, IProduct>;
   flashSale: FlashSale;
   onEndCountdownFlashSales: () => void;
}) {
   const [notification, setNotification] = useState<{ code: number; show: boolean }>();
   const { hours, minutes, seconds } = useCountdownTime(new Date(flashSale.info.startDateTime || 0), new Date(flashSale.info.endDateTime || 0), time => {
      onEndCountdownFlashSales();
   });
   const handleChangeNotification = (data: { code: number; show: boolean }) => {
      setNotification(data);
   };
   return (
      <section className="flash-sale">
         <div className="flash-sale__head ">
            <div className="flash-sale__head--left">
               <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path
                     d="M8 0C8.68355 4.10201 11.898 7.31645 16 8C11.898 8.68355 8.68355 11.898 8 16C7.31645 11.898 4.10201 8.68355 0 8C4.10201 7.31645 7.31645 4.10201 8 0Z"
                     fill="#FF6347"
                  />
               </svg>
               <h2 className="title">Flash sale</h2>
               <div className="flash-sale__countdown">
                  <div className="hour">{hours.toString().padStart(2, "0")}</div>:<div className="minute">{minutes.toString().padStart(2, "0")}</div>:
                  <div className="second">{seconds.toString().padStart(2, "0")}</div>
               </div>
            </div>
            <Link href="/shop/flash-sale" className="viewall">
               Xem tất cả{" "}
               <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                  <path
                     d="M3.99967 10.3754C4.0992 10.3759 4.19473 10.3362 4.26467 10.2654L8.26467 6.26541C8.4109 6.119 8.4109 5.88182 8.26467 5.73541L4.26467 1.73541C4.11692 1.59773 3.88667 1.60179 3.74386 1.7446C3.60105 1.88741 3.59699 2.11766 3.73467 2.26541L7.46967 6.00041L3.73467 9.73541C3.58844 9.88182 3.58844 10.119 3.73467 10.2654C3.80461 10.3362 3.90014 10.3759 3.99967 10.3754Z"
                     fill="#697F90"
                  />
               </svg>
            </Link>
         </div>
         <div className="flash-sale__content">
            {isMobile ? (
               <Slider className="d-block d-md-none" dots={false} speed={500} swipeToSlide slidesToShow={3.5} infinite={false}>
                  {paginateFlashSale && paginateFlashSale?.docs.length > 0
                     ? paginateFlashSale?.docs.slice(0, 6).map(product => {
                          return (
                             <div className="w-item" key={product._id}>
                                <CardProductV2
                                   showDes
                                   type="secondary"
                                   product={product}
                                   bookmark={product.__currentUserBookmark}
                                   onChangeNotification={noti => handleChangeNotification(noti)}
                                />
                             </div>
                          );
                       })
                     : [...Array(6)].map((e, i) => (
                          <div className="w-item" key={i}>
                             <Skeleton animation="wave" width="100%" height={170} />
                          </div>
                       ))}
               </Slider>
            ) : (
               <div className="d-none d-md-block">
                  <div className="row g-2">
                     {paginateFlashSale && paginateFlashSale?.docs?.slice(0, 6)?.length > 0
                        ? paginateFlashSale?.docs.slice(0, 6).map(product => {
                             return (
                                <div className="col-2" key={product._id}>
                                   <CardProductV2
                                      showDes
                                      product={product}
                                      bookmark={product.__currentUserBookmark}
                                      fixedHeight
                                      onChangeNotification={noti => handleChangeNotification(noti)}
                                   />
                                </div>
                             );
                          })
                        : [...Array(6)].map((e, i) => (
                             <div className="col-2" key={i}>
                                <Skeleton animation="wave" width="100%" height={170} />
                             </div>
                          ))}
                  </div>
               </div>
            )}
         </div>
         <Bookmark show={notification?.show} notification={notification?.code} handleClose={() => handleChangeNotification({ code: 0, show: false })} />
      </section>
   );
}

export default React.memo(FlashSaleSection, (prevProps, nextProps) => prevProps.flashSale._id !== nextProps.flashSale._id);
