import { useCallback, useEffect, useState } from "react";
import { getDateToDate } from "helpers";

export const STEEP_COUNT = {
   SOON: 1,
   START: 2,
   END: 3
};

function useCountdownTime(start, end, cbEnd) {
   const [counter, updateCounter] = useState({
      weekdays: 0,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      step: STEEP_COUNT.SOON
   });

   useEffect(() => {
      let interval;
      const startTimeNum = new Date(start).getTime();
      const endTimeNum = new Date(end).getTime();

      function run() {
         const currentTime = new Date().getTime();

         if (currentTime < startTimeNum) {
            const countdown = getDateToDate(currentTime, startTimeNum);
            updateCounter({
               ...countdown,
               step: STEEP_COUNT.SOON
            });
         } else if (currentTime >= startTimeNum && currentTime <= endTimeNum) {
            const countdown = getDateToDate(currentTime, endTimeNum);
            updateCounter({
               ...countdown,
               step: STEEP_COUNT.START
            });
         } else if (currentTime > endTimeNum) {
            const endTime = {
               weekdays: 0,
               days: 0,
               hours: 0,
               minutes: 0,
               seconds: 0,
               step: STEEP_COUNT.END
            };
            updateCounter(endTime);
            cbEnd?.(endTime);
            clearInterval(interval);
         }
      }

      if (startTimeNum && endTimeNum) {
         interval = setInterval(() => run(), 1000);
      }
      return () => clearInterval(interval);
   }, [start, end]);

   return { ...counter };
}
export default useCountdownTime;
